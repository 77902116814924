import { useEffect, useState } from 'react';
import cx from 'classnames';
import { BasketModal } from '../../components/modal/basketModal';
import { InstructionModal } from '../../components/modal/InstructionModal';
import { RewardModal } from '../../components/modal/RewardModal';
import { ASSETS } from '../../utils/assetUtils';
import classes from './game.module.css';
import { LuckySpin } from './luckySpin';
import { QuestSection } from './questSection';
import { Button } from '../../components/button/button';
import eventEmitter from '../../utils/eventEmitter';
import { EMITTER_EVENTS } from '../../constants';
import { VoucherItem } from '../../typings/voucher';

type Props = {
  //
};

export const GameScreen: React.FC<Props> = () => {
  // Modal states
  const [basketModalVisible, setBasketModalVisible] = useState(false);
  const [instructionModalVisible, setInstructionModalVisible] = useState(false);
  const [rewardModalVisible, setRewardModalVisible] = useState(false);

  const [reward, setReward] = useState<VoucherItem | undefined>();

  useEffect(() => {
    const handleShowReward = (reward: VoucherItem) => {
      setReward(reward);
      setRewardModalVisible(true);
    };
    const handleShowBasket = (reward: VoucherItem) => {
      setBasketModalVisible(true);
    };
    const handleScrollToMission = () => {
      const e = document.getElementById('MISSION_DETAIL');
      if (e) {
        e.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    eventEmitter.addListener(EMITTER_EVENTS.SHOW_REWARD, handleShowReward);
    eventEmitter.addListener(EMITTER_EVENTS.SHOW_BASKET, handleShowBasket);
    eventEmitter.addListener(EMITTER_EVENTS.VIEW_DETAIL_MISSION, handleScrollToMission);
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.SHOW_REWARD, handleShowReward);
      eventEmitter.removeListener(EMITTER_EVENTS.SHOW_BASKET, handleShowBasket);
      eventEmitter.removeListener(EMITTER_EVENTS.VIEW_DETAIL_MISSION, handleScrollToMission);
    };
  }, []);

  return (
    <div className={classes['container']}>
      <img className={classes['background']} src={ASSETS.BACKGROUND} alt="" />
      <div className={classes['content']}>
        <div className={classes['banner']}>
          <img src={ASSETS.GAME_BANNER} alt="" />
        </div>
        <div className={cx(classes['username'], 'pro-light')}>
          Xin chào, Quý khách&nbsp;
          <span className="pro-bold">Nguyễn Văn A</span>
        </div>

        <LuckySpin />

        <div className={classes['buttonRow']}>
          <Button
            title="giftButton"
            onClick={() => {
              setBasketModalVisible(true);
            }}
          >
            <div
              className={classes['actionButton']}
              style={{
                backgroundImage: `url(${ASSETS.BLUE_BUTTON})`,
              }}
            >
              <img src={ASSETS.GIFT_ICON} alt="" />
              <span className="pro-semibold">Giỏ quà</span>
            </div>
          </Button>
          <Button
            title="guideButton"
            onClick={() => {
              setInstructionModalVisible(true);
            }}
          >
            <div
              className={classes['actionButton']}
              style={{
                backgroundImage: `url(${ASSETS.BLUE_BUTTON})`,
              }}
            >
              <img src={ASSETS.INSTRUCTION_ICON} alt="" />
              <span className="pro-semibold">Hướng dẫn</span>
            </div>
          </Button>
        </div>

        <QuestSection />

        <Button
          title="exit"
          onClick={() => {}}
          customStyle={{
            marginTop: `calc(var(--game-width) * 0.3)`,
            marginBottom: `calc(var(--game-width) * 0.3)`,
          }}
        >
          <div
            className={classes['exitButton']}
            style={{
              backgroundImage: `url(${ASSETS.EXIT_BUTTON})`,
            }}
          >
            <span className="pro-semibold">Thoát trò chơi</span>
          </div>
        </Button>
      </div>

      <BasketModal
        visible={basketModalVisible}
        onDismiss={() => {
          setBasketModalVisible(false);
        }}
      />

      <InstructionModal
        visible={instructionModalVisible}
        onDismiss={() => {
          setInstructionModalVisible(false);
        }}
      />

      <RewardModal
        reward={reward}
        visible={rewardModalVisible}
        onDismiss={() => {
          setRewardModalVisible(false);
        }}
      />
    </div>
  );
};
