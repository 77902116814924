import { useCallback, useEffect, useMemo, useState } from 'react';
import { CANVAS_RATIO, EMITTER_EVENTS } from '../../constants';
import { VoucherItem, VoucherType } from '../../typings/voucher';
import { ASSETS } from '../../utils/assetUtils';
import eventEmitter from '../../utils/eventEmitter';
import { randomInArray } from '../../utils/math';
import { initGame } from './gameManager';
import classes from './luckySpin.module.css';

type Props = {
  //
};

export const LuckySpin: React.FC<Props> = () => {
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>();
  const [gameManager, setGameManager] = useState<any>();

  const canvasRefCallback = (node: HTMLCanvasElement) => {
    if (node && !canvasRef) {
      setCanvasRef(node);
    }
  };

  useEffect(() => {
    if (canvasRef) {
      (async () => {
        const gameManager = await initGame(canvasRef);
        setGameManager(gameManager);
      })();
    }
  }, [canvasRef]);

  useEffect(() => {
    return () => {
      gameManager && gameManager.dispose();
    };
  }, [gameManager]);

  const renderCanvas = useMemo(() => {
    return (
      <div className={classes['canvasWrapper']}>
        <CanvasBackground />
        <canvas className={classes['canvas']} ref={canvasRefCallback}></canvas>;
        <CanvasOverlay />
      </div>
    );
  }, []);

  return <div>{renderCanvas}</div>;
};

const CanvasOverlay = () => {
  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);

  const refCallBack = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const width = node.clientWidth;
      const height = node.clientHeight;
      let viewportWidth = 0;
      let viewportHeight = 0;
      if (width / height >= CANVAS_RATIO) {
        viewportHeight = height;
        viewportWidth = viewportHeight * CANVAS_RATIO;
      } else {
        viewportWidth = width;
        viewportHeight = viewportWidth / CANVAS_RATIO;
      }
      setWidth(viewportWidth);
      setheight(viewportHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rerenderCounter]
  );

  useEffect(() => {
    const handleResize = () => {
      setRerenderCounter(counter => counter + 1);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes['overlay']} ref={refCallBack}>
      <div
        className={classes['viewport']}
        style={{
          width,
          height,
        }}
      >
        <div
          className={classes['button']}
          onClick={() => {
            const indices = [
              VoucherType.VOUCHER_0K,
              VoucherType.VOUCHER_10K,
              VoucherType.VOUCHER_50K,
              VoucherType.VOUCHER_100K,
              VoucherType.VOUCHER_300K,
              VoucherType.VOUCHER_3000K,
            ];
            const randomType = randomInArray(indices);
            const demoReward: VoucherItem = {
              id: '123',
              receiveDate: '13/04/2023',
              type: randomType,
            };
            eventEmitter.emit(EMITTER_EVENTS.START_SPIN, demoReward);
          }}
          style={{
            position: 'absolute',
            width: height * 0.38,
            height: (height * 0.38 * 81) / 268,
            left: width / 2 - (height * 0.38) / 2,
            top: height * 0.83,
            // backgroundColor: `rgba(0,0,0,0.2)`,
          }}
        ></div>
      </div>
    </div>
  );
};

const CanvasBackground = () => {
  const [rerenderCounter, setRerenderCounter] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setheight] = useState(0);

  const refCallBack = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      const width = node.clientWidth;
      const height = node.clientHeight;
      let viewportWidth = 0;
      let viewportHeight = 0;
      if (width / height >= CANVAS_RATIO) {
        viewportHeight = height;
        viewportWidth = viewportHeight * CANVAS_RATIO;
      } else {
        viewportWidth = width;
        viewportHeight = viewportWidth / CANVAS_RATIO;
      }
      setWidth(viewportWidth);
      setheight(viewportHeight);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rerenderCounter]
  );

  useEffect(() => {
    const handleResize = () => {
      setRerenderCounter(counter => counter + 1);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes['background']} ref={refCallBack}>
      <div
        className={classes['viewport']}
        style={{
          width,
          height,
        }}
      >
        <div>
          <img
            className={classes['table']}
            style={{
              position: 'absolute',
              width: `calc(var(--game-width) * 1.1)`,
              height: `calc(var(--game-width) * 1.1 * 262 / 414)`,
              left: `calc(${width}px / 2 - var(--game-width) / 2)`,
              bottom: -height * 0.28,
            }}
            src={ASSETS.TABLE}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
