import { StoreProvider } from 'easy-peasy';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/layout/layout';
import { NoticeProvider } from './contexts/NoticeContext';
import { GameScreen } from './screens/game/game';
import store from './store';
import { soundUtils } from './utils/soundUtils';

function App() {
  // const [userInfo] = useUserInfo();

  useEffect(() => {
    soundUtils.setup();
    setTimeout(() => {
      (async () => {
        await soundUtils.initSounds('/assets/sounds/');
      })();
    }, 100);

    return () => {
      soundUtils.dispose();
    };
  }, []);

  return (
    <BrowserRouter basename={process.env.REACT_APP_APP_URL}>
      <Layout>
        {/* {!userInfo ? (
          <Routes>
            <Route path="/" element={<GameScreen />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        ) : ( */}
        <Routes>
          <Route path="/" element={<GameScreen />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* )} */}
      </Layout>
    </BrowserRouter>
  );
}

const WrappedApp = () => {
  return (
    <StoreProvider store={store}>
      <NoticeProvider>
        <App />
      </NoticeProvider>
    </StoreProvider>
  );
};

export default WrappedApp;
