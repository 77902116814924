import { VoucherType } from '../typings/voucher';

export const ASSETS = {
  BACKGROUND: 'assets/ui/background.png',
  BASKET_HEADER_BANNER: 'assets/ui/basket_header_banner.png',
  BLUE_BUTTON: 'assets/ui/blue_button.png',
  BOTTOM_DECO: 'assets/ui/bottom_deco.png',
  CHECK_ICON: 'assets/ui/check_icon.png',
  COMPLETE_BUTTON: 'assets/ui/complete_button.png',
  EXIT_BUTTON: 'assets/ui/exit_button.png',
  EXIT: 'assets/ui/exit.png',
  GAME_BANNER: 'assets/ui/game_banner.png',
  GIFT_ICON: 'assets/ui/gift_icon.png',
  INSTRUCTION_DECO: 'assets/ui/instruction_deco.png',
  INSTRUCTION_HEADER_BANNER: 'assets/ui/instruction_header_banner.png',
  INSTRUCTION_ICON: 'assets/ui/instruction_icon.png',
  METHOD1_ICON: 'assets/ui/method1_icon.png',
  METHOD2_ICON: 'assets/ui/method2_icon.png',
  MUTE_BUTTON: 'assets/ui/mute_button.png',
  UNMUTE_BUTTON: 'assets/ui/unmute_button.png',
  ORANGE_BUTTON: 'assets/ui/orange_button.png',
  REWARD_HEADER_BANNER: 'assets/ui/reward_header_banner.png',
  REWARD_MODAL_BD: 'assets/ui/reward_modal_bd.png',
  SHARE_BUTTON: 'assets/ui/share_button.png',
  SHORT_MODAL_BD: 'assets/ui/short_modal_bd.svg',
  SPEAKER_ICON: 'assets/ui/speaker_icon.png',
  SPIN_BUTTON: 'assets/ui/spin_button.png',
  TRASH_BANNER: 'assets/ui/trash_banner.png',
  VOUCHER_0K: 'assets/ui/voucher_0k.png',
  VOUCHER_10K: 'assets/ui/voucher_10k.png',
  VOUCHER_50K: 'assets/ui/voucher_50k.png',
  VOUCHER_100K: 'assets/ui/voucher_100k.png',
  VOUCHER_300K: 'assets/ui/voucher_300k.png',
  VOUCHER_3000K: 'assets/ui/voucher_3000k.png',
  WARNING_ICON: 'assets/ui/warning_icon.png',
  YELLOW_BUTTON: 'assets/ui/yellow_button.png',
  STROKE: 'assets/ui/stroke.svg',
  TABLE: 'assets/ui/table.png',
  ARROW: 'assets/ui/arrow.png',
};

export const VOUCHER_IMAGES = {
  [VoucherType.VOUCHER_0K]: ASSETS.VOUCHER_0K,
  [VoucherType.VOUCHER_10K]: ASSETS.VOUCHER_10K,
  [VoucherType.VOUCHER_50K]: ASSETS.VOUCHER_50K,
  [VoucherType.VOUCHER_100K]: ASSETS.VOUCHER_100K,
  [VoucherType.VOUCHER_300K]: ASSETS.VOUCHER_300K,
  [VoucherType.VOUCHER_3000K]: ASSETS.VOUCHER_3000K,
};
