import cx from 'classnames';
import { Button } from '../../components/button/button';
import { ASSETS } from '../../utils/assetUtils';

import classes from './questSection.module.css';

type Props = {
  //
};

const steps: {
  isCompleted: boolean;
  amount: number;
  des: string;
}[] = [
  {
    des: 'Bước 1. Cài đặt',
    amount: 1,
    isCompleted: true,
  },
  {
    des: 'Bước 2. Xác thực thiết bị',
    amount: 1,
    isCompleted: true,
  },
  {
    des: 'Bước 3. Xác minh giấy tờ',
    amount: 1,
    isCompleted: true,
  },
  {
    des: 'Bước 4. Xác thực khuôn mặt',
    amount: 1,
    isCompleted: false,
  },
  {
    des: 'Bước 5. Xác nhận đăng ký tài khoản',
    amount: 1,
    isCompleted: false,
  },
  {
    des: 'Bước 6. Đăng nhập Shinhan SOL',
    amount: 1,
    isCompleted: false,
  },
];

export const QuestSection: React.FC<Props> = () => {
  return (
    <div className={classes['container']} id="MISSION_DETAIL">
      <div className={classes['wrapper']}>
        <div className={cx(classes['title'], 'pro-bold')}>Tìm lượt quay</div>
        <div className={classes['section']}>
          <div className={classes['sectionHeader']}>
            <div className={classes['headerIconWrapper']}>
              <img src={ASSETS.METHOD1_ICON} alt="" />
            </div>
            <div className={classes['headerInfoWrapper']}>
              <div className={cx(classes['headerTitle'], 'pro-medium')}>Mở tài khoản qua eKYC</div>
              <div className={cx(classes['headerDes'], 'pro-light')}>
                01 lượt quay/bước (tối đa 06 lượt quay)
              </div>
            </div>
          </div>
          <div className={cx(classes['sectionDes'], 'pro-regular')}>
            Lần đầu tiên thực hiện thành công các bước trong quá trình mở tài khoản thanh toán trực
            tuyến qua quy trình định danh (eKYC) trên ứng dụng Shinhan SOL thời gian diễn ra chương
            trình (08/05/2023 - 06/06/2023). Cụ thể:
          </div>
          <div className={classes['stepsWrapper']}>
            {steps.map((step, index) => {
              return (
                <div className={classes['stepItem']} key={`step_${index}`}>
                  <div
                    className={classes['stepStatusIcon']}
                    style={{
                      backgroundColor: step.isCompleted ? '#FCA425' : '#BEC1C6',
                    }}
                  >
                    {step.isCompleted ? (
                      <img src={ASSETS.CHECK_ICON} alt="" />
                    ) : (
                      <span className="sh-bold">+{step.amount}</span>
                    )}
                  </div>
                  <div
                    className={cx(classes['stepDes'], 'pro-regular')}
                    style={{
                      color: step.isCompleted ? '#FCA425' : '#74767d',
                    }}
                  >
                    {step.des}
                  </div>
                  {index !== steps.length - 1 && <div className={classes['stepConnector']}></div>}
                </div>
              );
            })}
          </div>
          <div className={classes['warning']}>
            <img src={ASSETS.WARNING_ICON} alt="" />
            <div className="pro-regular">
              Quý khách phải hoàn thành ít nhất 03 bước đầu tiên mới đủ điều kiện tham gia quay số.
            </div>
          </div>
          <Button title="action">
            <div
              className={cx(classes['actionButton'], 'pro-bold')}
              style={{
                backgroundImage: `url(${ASSETS.YELLOW_BUTTON})`,
              }}
            >
              Thực hiện ngay
            </div>
          </Button>
        </div>
        <div className={classes['section']}>
          <div className={classes['sectionHeader']}>
            <div className={classes['headerIconWrapper']}>
              <img src={ASSETS.METHOD2_ICON} alt="" />
            </div>
            <div className={classes['headerInfoWrapper']}>
              <div className={cx(classes['headerTitle'], 'pro-medium')}>Đăng nhập hằng ngày</div>
              <div className={cx(classes['headerDes'], 'pro-light')}>Tối đa 1 lượt quay/ngày</div>
            </div>
          </div>
          <div className={cx(classes['sectionDes'], 'pro-regular')}>
            Thực hiện đăng nhập ứng dụng Shinhan SOL Việt Nam các ngày tiếp theo (chỉ áp dụng cho
            khách hàng thực hiện định danh tài khoản trong thời gian diễn ra chương trình).
          </div>
          <Button
            disabled={true}
            title="action"
            customStyle={{
              marginTop: `calc(var(--game-width) * 0.1)`,
            }}
          >
            <div
              className={classes['actionButton2']}
              style={{
                backgroundImage: `url(${ASSETS.COMPLETE_BUTTON})`,
              }}
            >
              <span className="pro-bold">Đã hoàn thành</span>
              <span className="pro-bold">23:45:00</span>
            </div>
          </Button>
        </div>
        <div className={classes['deco']}>
          <img src={ASSETS.BOTTOM_DECO} alt="" />
        </div>
      </div>
    </div>
  );
};
